
import api1183Icon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/api1183/icon.svg'
import asmeB31gIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/asmeB31g/icon.svg'
import bakeoutIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/bakeout/icon.svg'
import boilerTubeIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/boilerTube/icon.svg'
import bpcIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/bpc/icon.svg'
import bijlaardIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/bijlaard/icon.svg'
import branchIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/branch/icon.svg'
import burstPressureIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/burstPressure/icon.svg'
import charpyIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/charpy/icon.svg'
import cpDesignOffshoreIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/cpDesignOffshore/icon.svg'
import cpDesignIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/cpDesign/icon.svg'
import creepIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/creep/icon.svg'
import damageIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/damage/icon.svg'
import exemptionIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/exemption/icon.svg'
import dentFEAIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/dentFEA/icon.svg'
import fast2Icon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/fast2/icon.svg'
import fatigueIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/fatigue/icon.svg'
import fatigueDamageIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/fatigueDamage/icon.svg'
import featureIdentificationIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/featureIdentification/icon.svg'
import fatigueDataExplorerIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/fatigueDataExplorer/icon.svg'
import ffsBrittleIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsBrittle/icon.svg'
import ffsCrackLikeIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsCrackLike/icon.svg'
import ffsCreepIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsCreep/icon.svg'
import ffsDentGougeIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsDentGouge/icon.svg'
import ffsFatigueIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsFatigue/icon.svg'
import ffsFireDamageIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsFireDamage/icon.svg'
import ffsHydrogenIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsHydrogen/icon.svg'
import ffsLaminationIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsLamination/icon.svg'
import ffsFireDamageLvl1Icon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsFireDamageLvl1/icon.svg'
import ffsMisalignmentIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsMisalignment/icon.svg'
import ffsLtaIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsLta/icon.svg'
import ffsPart2Icon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsPart2/icon.svg'
import ffsPart1Icon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsPart1/icon.svg'
import ffsThinningIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsThinning/icon.svg'
import ffsPittingIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/ffsPitting/icon.svg'
import flangeIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/flange/icon.svg'
import hottapIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/hottap/icon.svg'
import hthaIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/htha/icon.svg'
import hthaSteadyStateIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/hthaSteadyState/icon.svg'
import idinterpIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/idinterp/icon.svg'
import inspectionReportIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/inspectionReport/icon.svg'
import insulationIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/insulation/icon.svg'
import largeLTAIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/largeLTA/icon.svg'
import intellijointIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/intellijoint/icon.svg'
import mixerIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/mixer/icon.svg'
import materialExplorerIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/materialExplorer/icon.svg'
import nozzleIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/nozzle/icon.svg'
import pipeSpanIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/pipeSpan/icon.svg'
import plausibleProfilesIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/plausibleProfiles/icon.svg'
import pipeIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/pipe/icon.svg'
import rainflowIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/rainflow/icon.svg'
import sandmanIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/sandman/icon.svg'
import shutdownIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/shutdown/icon.svg'
import shellIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/shell/icon.svg'
import simflexIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/simflex/icon.svg'
import smoothBarFatigueIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/smoothBarFatigue/icon.svg'
import storedEnergyIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/storedEnergy/icon.svg'
import tankSettlementFEAIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/tankSettlementFEA/icon.svg'
import tankIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/tank/icon.svg'
import tankSettlementIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/tankSettlement/icon.svg'
import tankSightIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/tankSight/icon.svg'
import tbreakIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/tbreak/icon.svg'
import toughnessIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/toughness/icon.svg'
import thermowellIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/thermowell/icon.svg'
import vanWinkleIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/vanWinkle/icon.svg'
import weldedJointFatigueIcon from '/home/runner/work/calculator-ui/calculator-ui/src/calculators/weldedJointFatigue/icon.svg'
const icons = {
  api1183: api1183Icon,
  asmeB31g: asmeB31gIcon,
  bakeout: bakeoutIcon,
  boilerTube: boilerTubeIcon,
  bpc: bpcIcon,
  bijlaard: bijlaardIcon,
  branch: branchIcon,
  burstPressure: burstPressureIcon,
  charpy: charpyIcon,
  cpDesignOffshore: cpDesignOffshoreIcon,
  cpDesign: cpDesignIcon,
  creep: creepIcon,
  damage: damageIcon,
  exemption: exemptionIcon,
  dentFEA: dentFEAIcon,
  fast2: fast2Icon,
  fatigue: fatigueIcon,
  fatigueDamage: fatigueDamageIcon,
  featureIdentification: featureIdentificationIcon,
  fatigueDataExplorer: fatigueDataExplorerIcon,
  ffsBrittle: ffsBrittleIcon,
  ffsCrackLike: ffsCrackLikeIcon,
  ffsCreep: ffsCreepIcon,
  ffsDentGouge: ffsDentGougeIcon,
  ffsFatigue: ffsFatigueIcon,
  ffsFireDamage: ffsFireDamageIcon,
  ffsHydrogen: ffsHydrogenIcon,
  ffsLamination: ffsLaminationIcon,
  ffsFireDamageLvl1: ffsFireDamageLvl1Icon,
  ffsMisalignment: ffsMisalignmentIcon,
  ffsLta: ffsLtaIcon,
  ffsPart2: ffsPart2Icon,
  ffsPart1: ffsPart1Icon,
  ffsThinning: ffsThinningIcon,
  ffsPitting: ffsPittingIcon,
  flange: flangeIcon,
  hottap: hottapIcon,
  htha: hthaIcon,
  hthaSteadyState: hthaSteadyStateIcon,
  idinterp: idinterpIcon,
  inspectionReport: inspectionReportIcon,
  insulation: insulationIcon,
  largeLTA: largeLTAIcon,
  intellijoint: intellijointIcon,
  mixer: mixerIcon,
  materialExplorer: materialExplorerIcon,
  nozzle: nozzleIcon,
  pipeSpan: pipeSpanIcon,
  plausibleProfiles: plausibleProfilesIcon,
  pipe: pipeIcon,
  rainflow: rainflowIcon,
  sandman: sandmanIcon,
  shutdown: shutdownIcon,
  shell: shellIcon,
  simflex: simflexIcon,
  smoothBarFatigue: smoothBarFatigueIcon,
  storedEnergy: storedEnergyIcon,
  tankSettlementFEA: tankSettlementFEAIcon,
  tank: tankIcon,
  tankSettlement: tankSettlementIcon,
  tankSight: tankSightIcon,
  tbreak: tbreakIcon,
  toughness: toughnessIcon,
  thermowell: thermowellIcon,
  vanWinkle: vanWinkleIcon,
  weldedJointFatigue: weldedJointFatigueIcon,
}
export default icons
